/* General Styles */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background: white;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}


/* read more */
.read-more-btn {
  display: inline-block;
  background-color: #060606e9;
  color: white;
  border: none;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.read-more-btn:hover {
  background-color: #5f5b5b;
}

/*pdf button */
.download-pdf-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-pdf-button:hover {
  background-color: #0056b3;
}


/*swipe up */
.swipe-up-section {
  opacity: 0;
  transform: translateY(50px); /* Start from below */
  transition: opacity 1.6s ease-in-out, transform 1.6s ease-out; /* Smooth transition */
}

.swipe-up-section.slide-up {
  opacity: 1;
  transform: translateY(0); /* Move to original position */
}


/* Header Styles */
.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
  color: rgb(255, 252, 252);
  opacity: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tagline {
  font-size: 12px; /* Adjust font size for the tagline */
  color: black; /* Example color */
  margin-top: 5px; /* Example margin top */
}

.wel {
  color: rgb(0, 0, 0);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-img {
  width: 250px;
  height: 50px;
  margin-left: 25%;
  }



nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 30px;
}

nav ul li a {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 14px;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #2b00ff;
}

.social-icons {
  display: flex;
  align-items: center;
  margin-right: 1%;
}

.social-icon {
  margin-left: 15px;
  font-size: 14px;
  transition: transform 0.2s ease-in-out;
}

.social-icon:hover {
  transform: scale(1.1);
}

.icon {
  display: block;
  width: 24px; /* Adjust size as necessary */
  height: 24px; /* Adjust size as necessary */
}

/*social-icons*/

.facebook-icon .icon {
  color: #3b5998; /* Facebook blue */
}

.linkedin-icon .icon {
  color: #0077b5; /* LinkedIn blue */
}

.instagram-icon .icon {
  color: #E1306C; /* Instagram gradient color */
}

.youtube-icon .icon {
  color: #FF0000; /* YouTube red */
}

.icon.heartbeat {
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.hamburger {
  display: none;
  font-size: 24px;
  cursor: pointer;
  margin-left: auto;
  color: #2980b9;
}


/* Animation */
.slide-animation {
  animation-name: slide;
  animation-duration: 0.2s;
}

@keyframes slide {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.heartbeat {
  animation-name: heartbeat;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}





@keyframes writing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  0%, 49% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
}

@keyframes colorChange {
  0%, 100% {
    color: #333;
  }
  50% {
    color: #1abc9c;
  }
}

.animated-heading {
  text-align: center;
  font-size: 3rem;
  margin-top: 2rem;
}

.animated-heading .writing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: writing 4s steps(30, end), blink 1s step-end infinite alternate;
}

/* Cards Styles */
.features-container {
  padding: 20px; /* Example padding for the container */
}

.cards {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  border: 20%;
  padding: 0.5px 0;
  
}

.card {
  background: linear-gradient(to left, #f5e3e6, #d9e4f5, #ffffff);
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;

}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front, .card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-front {
  background: linear-gradient(to right, #fc5c7cc1, #6a82fbc6);
}

.card-back {
  background: linear-gradient(to right, #4568dc64, #b16ab37f);
  transform: rotateY(180deg);
  background-image: url('./assets/images/STEM_LOGO.png');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.card h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.card p {
  font-size: 16px;
  color: #000000;
  font-weight: lighter;
  margin-top: auto;
}

.features-container {
  padding: 20px; /* Example padding for the container */
}

.cards-client {
  display: flex;
  justify-content: space-around;
  margin: 20px;

}



.cardc {
 
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  overflow: hidden; /* Hide overflow to create the sliding effect */
  position: relative; /* Make the card a relative container */
}

.cardc-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardc-front, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}

.cardc-front {
  background: linear-gradient(to left, #f5e3e6, #d9e4f5, #ffffff);
  z-index: 1; /* Ensure the front is above the back */
}

.cardc-back {
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  color: #fff; /* White text for the back */
  transform: translateY(-100%); /* Position the back above the front */

}

.cardc:hover .cardc-back {
  transform: translateY(0); /* Slide down the back on hover */
  z-index: 2; /* Ensure the back is above the front */
}

.cardc h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardc p {
  font-size: 16px;
  color: #575454;
}

.cardc-front-claret {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/claret.jpg');  /* Replace with your image path */
  background-size:90% 89% ;
  background-repeat: no-repeat;
  background-position: center; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}

.cardc-front-dps {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/dps.jpg');  /* Replace with your image path */
  background-size:90% 89% ;
  background-repeat: no-repeat;
  background-position: center; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}


.cardc-front-aps {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/assamvalley.jpg');  /* Replace with your image path */
  background-size: cover;
  background-size:90% 89% ;
  background-repeat: no-repeat;
  background-position: center; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}

.cardc-front-sbgj {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/sbgj.jpg');  /* Replace with your image path */
  background-size:99% 96.5% ;
  background-repeat: no-repeat;
  background-position: left; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}

.cardc-front-shem {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/shem.jpg');  /* Replace with your image path */
  background-size:99% 96.5% ;
  background-repeat: no-repeat;
  background-position: left; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}


.cardc-front-gps {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/GPS.jpg');  /* Replace with your image path */
  background-size:90% 90% ;
  background-repeat: no-repeat;
  background-position: center; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}

.cardc-front-apsn {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
  background-image: url('./assets/images/apsn.png');  /* Replace with your image path */
  background-size:90% 90% ;
  background-repeat: no-repeat;
  background-position: center; /* Align background image to the left */
  border-radius: 0 20px 20px 0
}



.cardc:hover .cardc-back {
  transform: translateY(0); /* Slide down the back on hover */
  z-index: 2; /* Ensure the back is above the front */
}

.cardc-front-aps, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}


.cardc-front-apsn, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}


.cardc-front-dps, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}


.cardc-front-claret, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}

.cardc-front-sbgj, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}

.cardc-front-shem, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}

.cardc-front-gps, .cardc-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}


.features-container {
  padding: 20px; /* Example padding for the container */
}
.carda {
  margin: 10px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 30%;
  height: 300px; /* Fixed height for consistency */
  overflow: hidden; /* Hide overflow to create the sliding effect */
  position: relative; /* Make the card a relative container */
}

.carda-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .carda-front, .carda-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
  }
  
  .carda-front {
    background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
    z-index: 1; /* Ensure the front is above the back */
  }
  
  .carda-back {
    background: rgba(0, 0, 0, 0.8); /* Dark overlay */
    color: #fff; /* White text for the back */
    transform: translateY(-100%); /* Position the back above the front */
  
  }
  
  .carda:hover .carda-back {
    transform: translateY(0); /* Slide down the back on hover */
    z-index: 2; /* Ensure the back is above the front */
  }
  
  .carda h3 {
    margin: 20px 0 10px;
    font-size: 24px;
  }
  
  .carda p {
    font-size: 16px;
    color: #575454;
  }
  
  .carda-front-sta {
    background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
    z-index: 1; /* Ensure the front is above the back */
    background-image: url('./assets/images/assam.jpeg');  /* Replace with your image path */
    background-size: 480px 350px;
    background-position: center;
    background-repeat: no-repeat; /* Center the background image */
  
  }
  
  .carda-front-sti {
    background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
    z-index: 1; /* Ensure the front is above the back */
    background-image: url('./assets/images/india.jpg');  /* Replace with your image path */
    background-size: 480px 350px;
    background-position: center;
    background-repeat: no-repeat; /* Center the background image */
  }
  
  
  .carda-front-abc {
    background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
    z-index: 1; /* Ensure the front is above the back */
    background-image: url('./assets/images/udyam.png');  /* Replace with your image path */
    background-size: 480px 350px;
    background-position: center;
    background-repeat: no-repeat; /* Center the background image */
  
  }

  .carda-front-meity {
    background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
    z-index: 1; /* Ensure the front is above the back */
    background-image: url('./assets/images/meity.jpg');  /* Replace with your image path */
    background-size: 480px 350px;
    background-position: center;
    background-repeat: no-repeat; /* Center the background image */
  
  }
  
  
  
  
  
  .carda:hover .carda-back {
    transform: translateY(0); /* Slide down the back on hover */
    z-index: 2; /* Ensure the back is above the front */
  }
  
  .carda-front-sta, .carda-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
  }
  
  .carda-front-sti, .carda-front-meity, .carda-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
  }
  
  
  .carda-front-xyz, .carda-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
  }
  
  .carda-front-abc, .carda-back {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
  }
  


/* Additional Section 1 Styles */
.school-info {
  background: rgba(211, 211, 211, 0.134);

    padding: 10px 20px;
    text-align: center;
    transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
    

  font-family: 'Times New Roman', Times, serif;
  font-size: larger;
  
}

.school-info:hover {
  box-shadow: 0 0 150px rgba(255, 255, 255, 0.8);
}

.school-info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.school-info-text {
  width: 50%;
  text-align: center;
}

@keyframes bounceMove {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(30px, -50px); /* Move right */
  }
  40% {
    transform: translate(-200px, -100px); /* Move left */
  }
  60% {
    transform: translate(30px, 50px); /* Move right */
  }
  80% {
    transform: translate(-150px, 100px); /* Move left */
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes glow {
  0%, 100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  50% {
    box-shadow: 0 0 50px rgba(255, 255, 255, 1);
  }
}

.school-info-image {
  position: relative;
  width: 480px;
  height: 480px;
  background-image: url('./assets/images/ABC2.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to right, #bbd2c54c, #53697615, #292e4959);
    border-radius: 50%;
  }

  animation: bounceMove 5s ease-in-out infinite, glow 3s ease-in-out infinite;
  transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.school-info-image:hover {
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.507);
}



.photo-info{
 
  padding: 10px 0;
  text-align: center;
  transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);

 background-size: cover;
 background-repeat: no-repeat;
 margin: 0 10px;

}

.photo-info-image{
 position: relative;
 width: 100%;
 height: 500px;
 border:transparent;
 border-radius: 20px;
 background-image: url('./assets/images/picture.png');
 
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}

.contact-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.contact-card, .call-form-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 48%;
}

.call-form-section {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.map-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.call-form-section form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.call-form-section label {
  margin-bottom: 5px;
}

.call-form-section input, .call-form-section textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: calc(100% - 20px); /* Ensure input and textarea are 100% width */
}

.call-form-section button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.call-form-section button:hover {
  background-color: #0056b3;
}



.aim-section {
  text-align: center;
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  padding: 10px 0;
}

.aim-section h1 {
  font-size: 2.5em;
  margin-bottom: 0.5px;
}

.aim-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 40px 0;
  padding: 0 20px;
}

.aim-card {
  background-color: #000;
  border-radius: 8px;
  padding: 20px;
  width: 23%;
  position: relative;
}

.aim-card-image {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 2px solid #000;
  margin: 0 auto 20px;
}

.aim-card-text p {
  color: #fff;
  font-size: 1em;
  font-weight: bold;
}

/* Academics.css */

.academics-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.section {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section h2 {
  margin-bottom: 10px;
  color: #333;
  text-align: center; /* Center align the section headings */
}

.cards-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center; /* Center align cards horizontally */
}

.communication-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.communication {
  width: 480px;
  height: 480px;
  background-image: url('./assets/images/mridul.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}

.communication-text  {
  max-width: 50%; /* Adjust this value as needed */
  text-align: justify; /* Justify the text alignment */
  font-size: 20px;
  font-weight: light;
  font-family: sans-serif;
}


.academic-card {
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  width: 200px;
  
  text-align: center; /* Center align card contents */
}

.academic-card:hover {
  transform: translateY(-5px);
}

.profile-pic {
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  overflow: hidden;
  margin: 0 auto; /* Center profile picture */
}

.profile-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  margin-top: 10px; /* Add spacing between profile pic and info */
}



.achievements {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}


.achievement-card {
 
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  overflow: hidden; /* Hide overflow to create the sliding effect */
  position: relative; /* Make the card a relative container */
}

.achievement-card-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.achievement-card-front, .achievement-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s;
}

.achievement-card-front {
  background: linear-gradient(to right, #cc95c0, #dbd4b4, #7aa1d2);
  z-index: 1; /* Ensure the front is above the back */
}

.achievement-card-back {
  background: rgba(0, 0, 0, 0.8); /* Dark overlay */
  color: #fff; /* White text for the back */
  transform: translateY(-100%); /* Position the back above the front */

}

.achievement-card:hover .achievement-card-back {
  transform: translateY(0); /* Slide down the back on hover */
  z-index: 2; /* Ensure the back is above the front */
}

.achievement-card h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.achievement-card p {
  font-size: 16px;
  color: #575454;
}



.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px auto;
  max-width: 100vw;
  background-color: #E6E6FA
}

.contact-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 78%;
  padding: 20px;
}

.contact-card {
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 45%;
  margin: 20px;
}

.contact-card h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.school-details h2 {
  font-size: 20px;
  color: #333333;
  text-align: left;
  display: flex;
  align-items: center;
}

.get{
  display: flex;
  justify-content: flex-start;
}
.school-details p {
  font-size: 16px;
  color: #666666;
  text-align: left;
}

.address-details {
  display: flex;
  flex-direction: column;
  
}

.address-details p {
  font-size: 16px;
  color: #666666;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.address-details a {
  color: #007bff;
  text-decoration: none;
  margin-left: 5px;
}

.address-details a:hover {
  text-decoration: underline;
}

.address-details span {
  font-weight: bold;
  margin-left: 5px;
}

.icon-con {
  margin-right: 8px;
  color: #ee5a2d;
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.icon-con:hover {
  transform: scale(1.2);
}

.map-container {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

iframe {
  width: 100%;
  height: 450px;
  border: 0;
}



.footer {
  text-align: center;
padding: 0.5px;
  margin-top: 40px;
  opacity: 98%;
  background: linear-gradient(to bottom, #e6e9f0 0%, #eef1f5 100%);
  color: rgb(0, 0, 0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}

.contact-details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.contact-item {
  display: flex;
  align-items: center;
}

.icon-con {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.cardk {
  background: white;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;

}

.cardk-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardk:hover .cardk-inner {
  transform: rotateY(180deg);
}

.cardk-front, .cardk-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardk-front {
 
  color: rgba(0, 0, 0, 0.834);
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
}

.cardk-back {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  transform: rotateY(180deg);
  background-image: url('./assets/images/UNO.png');
  background-size: cover;
}

.cardk h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardk p {
  font-size: 16px;
  color: #575454;
}

.cardj {
  background: white;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;

}

.cardj-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardj:hover .cardj-inner {
  transform: rotateY(180deg);
}

.cardj-front, .cardj-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardj-front {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  color: rgba(0, 0, 0, 0.834);
}

.cardj-back {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  transform: rotateY(180deg);
  background-image: url('./assets/images/joystick.png');
  background-size: cover;
}

.cardj h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardj p {
  font-size: 16px;
  color: #575454;
}

.cardd {
  background:white;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;

}

.cardd-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardd:hover .cardd-inner {
  transform: rotateY(180deg);
}

.cardd-front, .cardd-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardd-front {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  color: rgba(0, 0, 0, 0.834);
}

.cardd-back {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  transform: rotateY(180deg);
  background-image: url('./assets/images/display.png');
  background-size: cover;
}

.cardd h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardd p {
  font-size: 16px;
  color: #575454;
}

.cardm {
  background: white;
  border: 1px solid #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 20%;
  height: 300px; /* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;

}

.cardm-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardm:hover .cardm-inner {
  transform: rotateY(180deg);
}

.cardm-front, .cardm-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardm-front {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  color: rgba(0, 0, 0, 0.834);
  

}

.cardm-back {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  transform: rotateY(180deg);
  background-image: url('./assets/images/DRIVER.png');
  background-size: cover;
}

.cardm h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardm p {
  font-size: 16px;
  color: #575454;
}


.kit-info {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  padding: 40px 20px;
  text-align: center;
  transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.kit-info:hover {
  box-shadow: 0 0 150px rgba(255, 255, 255, 0.8);
}

.kit-info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.kit-info-text {
  width: 50%;
  text-align: center;
  margin-bottom: auto;
  font-size: x-large;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}



/* KIT IMAGE */

.cardkit {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  border-radius: 20%;
  padding: 20px;
  text-align: center;
  width: 600px;
  height: 380px;/* Fixed height for consistency */
  perspective: 1000px; /* Add perspective for 3D effect */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

}

.cardkit-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardkit:hover .cardkit-inner {
  transform: rotateY(180deg);
}

.cardkit-front, .cardkit-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* For Safari */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardkit-front {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  color: rgba(0, 0, 0, 0.834);
  background-image: url('./assets/images/Kit.jpg');
  position: relative;
  width: 650px;
  height: 380px;
  
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  

}

.cardkit-back {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  transform: rotateY(180deg);
  background-image: url('./assets/images/items.jpg');
  background-size: cover;
  background-size: 650px 380px;
}

.cardkit h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.cardkit p {
  font-size: 16px;
  color: #575454;
}


/* Service section */
.service-info {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  padding: 40px 20px;
  text-align: center;
  transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.service-info:hover {
  box-shadow: 0 0 150px rgba(255, 255, 255, 0.8);
}

.service-info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-info-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between the cards */
}

.service-info-image {
  width: 500px; /* Adjust as needed */
  height: 500px; /* Adjust as needed */
  background-image: url('./assets/images/stemimage.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Service page cards */
.cardser1, .cardser2, .cardser3, 
.cardser4, .cardser5, .cardser6 {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  border: 1px solid #ffffff;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: justify;
  width: 400px;
  height: 110px;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cardser1 h3, .cardser2 h3, .cardser3 h3,
.cardser4 h3, .cardser5 h3, .cardser6 h3 {
  margin: 0;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}

/*scroll button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
  color: #020202;
  border: 2px solid transparent; /* Transparent border */
  border-radius: 50%;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 30px;
  display: none;
  transition: opacity 0.3s ease;
  text-align: center; /* Center the arrow */
  line-height: 30px; /* Center the arrow vertically */
}

.scroll-to-top.visible {
  display: block;
}

@media (max-width: 1200px) {
  .cards, .cards-client {
    flex-direction: column;
    align-items: center;
  }

  .card, .cardc {
    width: 80%;
    margin-bottom: 20px;
  }
.footer{

  margin-top: 35%;

}
/* Service section */
.service-info {
  padding: 35px 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 40px;
}

.service-info-content {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between the columns */
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.service-info-column {
  display: flex;
  flex-direction: column;
  gap: 18px; /* Space between the cards */
  width: 30%; /* Ensure the columns fit within the container */
}

.service-info-image {
  width: 400px; /* Adjusted size */
  height: 400px; /* Adjusted size */
  background-image: url('./assets/images/stemimage.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  animation: rotate 20s linear infinite;
  flex-shrink: 0; /* Prevents shrinking */
}

/* Service page cards */
.cardser1, .cardser2, .cardser3, 
.cardser4, .cardser5, .cardser6 {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  border: 1px solid #ffffff;
  border-radius: 35px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px;
  text-align: justify;
  width: 100%; /* Cards take full width of the column */
  height: auto; /* Adjust height for content */
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cardser1 h3, .cardser2 h3, .cardser3 h3,
.cardser4 h3, .cardser5 h3, .cardser6 h3 {
  margin: 0;
  font-size: 22px; /* Adjusted font size */
  font-family: 'Times New Roman', Times, serif;
}
  .cardk, .cardj, .cardm, .cardd {
    width: 400px;
    height: 200px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .cardk-front, .cardj-front, .cardm-front, .cardd-front, 
  .cardk-back, .cardj-back, .cardm-back, .cardd-back {
    position: absolute;
    width: 400px;
    height: 200px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardk-inner, .cardj-inner, .cardm-inner, .cardd-inner {
    padding: 20px;
  }

  .cardk h3, .cardj h3, .cardm h3, .cardd h3 {
    font-size: 20px;
  }

  .cardk p, .cardj p, .cardm p, .cardd p {
    font-size: 14px;
  }
  .carda {
    width: 650px;
    height: 350px;
    padding: 20px;
    margin: 25px;
  }

  .carda-front, .carda-back {
    padding: 20px;
    font-size: 14px;
  }

  .carda h3 {
    font-size: 20px;
  }

  .carda p {
    font-size: 14px;
  }

  .carda-front-sta, .carda-front-sti, .carda-front-abc {
    background-size: cover;
    background-position: center;
    width: 650px;
    height: 350px;
  }
  
  .photo-info {
    padding: 10px 0; /* Adjust the padding as needed */
    margin: 0 5px;   /* Adjust the margin as needed */
     height: 450px;
  }
  
  .photo-info-image {
    position: relative;
    width: 100%;
     /* Adjust the height as needed */
    border-radius: 10px; /* Adjust the border-radius as needed */
    background-size: contain;
    background-repeat: no-repeat;
  }
  
}

@media (max-width: 920px) {


  nav {
    display: none;
  }

  nav.open {
    display: block;
    position: absolute;
    top: 60px; /* Adjust according to your header height */
    left: 0;
    width: 100%;
    background: linear-gradient(to left, #f5e3e6, #d9e4f5, #ffffff);
    text-align: center;
    z-index: 999;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin: 10px 0;
  }

  .hamburger {
    display: block;
  }

  .header {
    flex-direction: row;
    
  }

  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-img {
    width: 250px;
    height: 50px;
    margin-left: 25%;
    }
  .logo-text {
    font-size: 35px;
  }

  nav ul {
    flex-direction: column;
    background: white;
  }

  nav ul li {
    margin-left: 0;
    margin-top: 10px;
  }

  .social-icons {
    margin-top: 10px;
    margin-left: auto;
  }

  .card, .cardc, .card-sarala, .card-army, .card-dps, .card-claret, .card-shem, .card-gps, .card-apsn {
    width: 70%;
    margin-right: 20px;
  }

  .kit-info {
    width: 80%;
    margin: 0 auto;
    padding: 20px 10px;
    border-radius: 20px;
  }

  .kit-info-text {
    width: 100%;
    font-size: large;
  }

  .cardkit {
    width: 70%;
    height: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardkit-inner {
    width: 70%;
    display: flex;
    height: 50px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .cardkit-front, .cardkit-back {
    width: 100%;
    height: 100px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardkit-front {
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    color: rgba(0, 0, 0, 0.834);
    background-image: url('./assets/images/Kit.jpg');
    position: relative;
    width: 100%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 25px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .cardkit-back {
    background: linear-gradient(to left, #cc95c0, #dbd4b4, #7aa1d2);
    transform: rotateY(180deg);
    background-image: url('./assets/images/items.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    border-radius: 25px;
    padding: 20px;
  }

  .cardkit h3 {
    font-size: 20px;
  }

  .cardkit p {
    font-size: 14px;
    color: #575454;
  }

  .cardk, .cardj, .cardm, .cardd {
    width: 300px;
    height: 200px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .cardk-front, .cardj-front, .cardm-front, .cardd-front, 
  .cardk-back, .cardj-back, .cardm-back, .cardd-back {
    position: absolute;
    width: 300px;
    height: 200px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardk-inner, .cardj-inner, .cardm-inner, .cardd-inner {
    padding: 20px;
  }

  .cardk h3, .cardj h3, .cardm h3, .cardd h3 {
    font-size: 20px;
  }

  .cardk p, .cardj p, .cardm p, .cardd p {
    font-size: 14px;
  }

  .service-info {
    width: 80%;
    margin: 0 auto;
    border-radius: 20px;
  }

  .service-info-content {
    flex-direction: column;
  }

  .service-info-text {
    width: 100%;
    font-size: large;
  }

  .service-info-image {
    width: 100%;
    height: auto;
  }

  .cardser {
    width: 100%;
    height: 50px;
    padding: 5px;
  }

  .cardser h3 {
    font-size: 20px;
  }

  .service-info-image {
    position: relative;
    width: 200px;
    height: 200px;
    background-image: url('./assets/images/stemimage.png');
    background-size: cover;
    background-position: center;
    animation: rotate 20s linear infinite;
  }




 

 
  .map-container iframe {
    position: relative;
    top: -30px;
    height: 450px;
    width: 90%;
    margin-right: 15%;
  }

  .contact-card {
    border-radius: 5px;
    padding: 10px;
    height: 40%;
    margin-right: 25%;
  }

  .communication-container {
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .communication {
    width: 300px;
    height: 300px;
  }

  .communication-text {
    max-width: 80%;
    font-size: 16px;
    text-align: center;
  }

  .carda {
    width: 450px;
    height: 250px;
    padding: 20px;
    margin: 25px;
  }

  .carda-front, .carda-back {
    padding: 20px;
    font-size: 14px;
  }

  .carda h3 {
    font-size: 20px;
  }

  .carda p {
    font-size: 14px;
  }

  .carda-front-sta, .carda-front-sti, .carda-front-abc {
    background-size: cover;
    background-position: center;
    width: 450px;
    height: 250px;
  }

  .photo-info {
    padding: 10px 0; /* Adjust the padding as needed */
    margin: 0 5px;   /* Adjust the margin as needed */
     height: 350px;
  }
  
  .photo-info-image {
    position: relative;
    width: 100%;
     /* Adjust the height as needed */
    border-radius: 10px; /* Adjust the border-radius as needed */
    background-size: contain;
    background-repeat: no-repeat;
  }
  
}


@media (max-width: 768px) {

    .header {
    flex-direction: row;
    align-items: flex-start;
   
  }

  .logo-img {
    width: 200px; /* Adjust size as necessary */
    height: 80px;
    margin-left: 0;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
    background: white;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .social-icons {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: auto;
  }

  .social-icon {
    margin-left: 0;
    margin-right: 15px;
  }

  .hamburger {
    display: block;
    margin-top: 3%;
    
  }
  

  .card, .cardc, .card-sarala, .card-army, .card-dps, .card-claret, .card-shem, .card-gps, .card-apsn {
    width: 80%;
    margin-right: 1%;
    
  }

  .kit-info {
    width: 90%; /* Adjust this value as needed to control the width */
  margin-left: 25px;
  margin-right: 25px;
    padding: 20px 10px; /* Padding for inner content */
    border-radius: 20px; /* Rounded corners */
  }

  .kit-info-text {
    width: 100%;
    font-size: large;
  }

  .cardkit {
    width: 70%; /* Full width for better fit on small screens */
    height: 50px; /* Adjust height automatically */
    padding: 10px; /* Reduced padding for small screens */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardkit-inner {
    width: 70%; /* Full width for better alignment */
    display: flex;
    height: 250px;
    flex-direction: column; /* Column direction for stacking */
    align-items: center; /* Center content */
    justify-content: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    
  }

  .cardkit-front, .cardkit-back {
    width: 100%; /* Full width for both sides */
    height: 250px; /* Adjust height automatically */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* For Safari */
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardkit-front {
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    color: rgba(0, 0, 0, 0.834);
    background-image: url('./assets/images/Kit.jpg');
    
    width: 100%;
    height: 200px; /* Adjusted height for better visibility */
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-position: center;
  
    padding: 20px; /* Add padding for better content alignment */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better appearance */
  }
  
  .cardkit-back {
    background: linear-gradient(to left, #cc95c0, #dbd4b4, #7aa1d2);
    transform: rotateY(180deg);
    background-image: url('./assets/images/items.jpg');
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; 
    background-position: center; /* Prevent the image from repeating */
    width: 100%;
    height: 200px; /* Adjusted height for better visibility */
    border-radius: 25px;
    padding: 20px; /* Add padding for better content alignment */
  }

  .cardkit h3 {
    font-size: 20px; /* Adjusted font size */
  }

  .cardkit p {
    font-size: 14px; /* Adjusted font size */
    color: #575454;
  }

  .cardk, .cardj, .cardm, .cardd {
    width: 300px; /* Full width for better fit on small screens */
    height: 200px;
    padding: 20px;
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .cardk-front, .cardj-front, .cardm-front, .cardd-front, 
  .cardk-back, .cardj-back, .cardm-back, .cardd-back {
    position: absolute;
    width: 300px;
    height: 200px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* For Safari */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardk-inner, .cardj-inner, .cardm-inner, .cardd-inner {
    padding: 20px;
  }

  .cardk h3, .cardj h3, .cardm h3, .cardd h3 {
    font-size: 20px;
  }

  .cardk p, .cardj p, .cardm p, .cardd p {
    font-size: 14px;
  }

/* Service section */
.service-info {
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  padding: 40px 20px;
  text-align: center;
  transition: box-shadow 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  border-radius: 50px;

}

.service-info:hover {
  box-shadow: 0 0 150px rgba(255, 255, 255, 0.8);
}

.service-info-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service-info-column {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between the cards */
}

.service-info-image {
  width: 500px; /* Adjust as needed */
  height: 500px; /* Adjust as needed */
  background-image: url('./assets/images/stemimage.png');
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Service page cards */
.cardser1, .cardser2, .cardser3, 
.cardser4, .cardser5, .cardser6 {
  background: linear-gradient(to right, #fc5c7d, #6a82fb);
  border: 1px solid #ffffff;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: justify;
  width: 400px;
  height: 110px;
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.cardser1 h3, .cardser2 h3, .cardser3 h3,
.cardser4 h3, .cardser5 h3, .cardser6 h3 {
  margin: 0;
  font-size: 25px;
  font-family: 'Times New Roman', Times, serif;
}
 
  .map-container iframe {
    position: relative;
    top: -30px;
    height: 450px;
    width: 90%;
    margin-right: 15%;
  }

  .contact-card {
    border-radius: 5px;
    padding: 10px;
    height: 40%;
    margin-right: 25%;
  }

  .communication-container {
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .communication {
    width: 300px;
    height: 300px;
  }

  .communication-text {
    max-width: 80%;
    font-size: 16px;
    text-align: center; /* Adjust text alignment for better readability on small screens */
  }

  .carda {
    width: 450px; /* Make the card take full width on small screens */
    height: 250px; /* Allow height to adjust based on content */
    padding: 20px;
    margin: 25px; /* Reduce padding for smaller screens */
  }

  .carda-front, .carda-back {
    padding: 20px; /* Adjust padding for inner content */
    font-size: 14px; /* Adjust font size for readability */
  }

  .carda h3 {
    font-size: 20px; /* Adjust heading size */
  }

  .carda p {
    font-size: 14px; /* Adjust paragraph size */
  }

  .carda-front-sta, .carda-front-sti, .carda-front-abc {
    background-size: cover; /* Ensure the entire image fits within the card */
    background-position: center;
    width: 450px;
    height: 250px;
  }
  .contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
   
    
    .contact-card {
      border-radius: 5px;
      padding: 10px;
      height: 40%;
      margin-left: 30%;
    }

    .contact-form {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-card {
      width: 90%;
    }
  
    .map-container {
      width: 90%;
    }
  
    .call-form-section{
      border-radius: 10px;
      padding: 10px;
      height: 40%;
      width: 250px;
      margin-left: 2%;
      margin-right: 2%;
    }
  
    
  .map-container iframe{
   margin-left: 15%;
   padding-top: 40px;
   height: 10%;
   
  }

  .photo-info {
    padding: 10px 0; /* Adjust the padding as needed */
    margin: 0 5px;   /* Adjust the margin as needed */
     height: 350px;
  }
  
  .photo-info-image {
    position: relative;
    width: 100%;
     /* Adjust the height as needed */
    border-radius: 10px; /* Adjust the border-radius as needed */
    background-size: contain;
    background-repeat: no-repeat;
  }
  
 
}

@media (max-width: 550px) {

  .header {
    flex-direction: row;
    align-items: flex-start;
   
  }

  .logo-img {
    width: 90px; /* Adjust size as necessary */
    height: 50px;
    margin-left: 0;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  
    background: white;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .social-icons {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: auto;
  }

  .social-icon {
    margin-left: 0;
    margin-right: 15px;
  }

  .hamburger {
    display: block;
    margin-top: 3%;
    
  }
  

  .logo-container {
    flex-direction: column;
  }

  .logo-text {
    font-size: 20px;
  }

  nav ul {
    flex-direction: column;
    background: white;
  }

  nav ul li {
    margin-left: 0;
    margin-top: 10px;
  }

  .social-icons {
    margin-top: 10px;
  }

  .card, .cardc {
    width: 100%;
  }

  /* General adjustments for small screens */
  .kit-info {
    width: 80%; /* Adjust this value as needed to control the width */
    margin: 0 auto; /* Center horizontally */
    padding: 20px 10px; /* Padding for inner content */

    border-radius: 20px; /* Rounded corners */
    
  }
  
  .kit-info-text {
    width: 100%;
    font-size: large;
  }

  .cardkit {
    width: 70%; /* Full width for better fit on small screens */
    height: 50px; /* Adjust height automatically */
    padding: 10px; /* Reduced padding for small screens */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardkit-inner {
    width: 70%; /* Full width for better alignment */
    display: flex;
    height: 50px;
    flex-direction: column; /* Column direction for stacking */
    align-items: center; /* Center content */
    justify-content: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .cardkit-front, .cardkit-back {
    width: 100%; /* Full width for both sides */
    height: 100px; /* Adjust height automatically */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* For Safari */
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardkit-front {
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    color: rgba(0, 0, 0, 0.834);
    background-image: url('./assets/images/Kit.jpg');
    position: relative;
    width: 100%;
    height: 200px; /* Adjusted height for better visibility */
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-position: center;
    border-radius: 25px;
    padding: 20px; /* Add padding for better content alignment */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better appearance */
  }
  
  .cardkit-back {
    background: linear-gradient(to left, #cc95c0, #dbd4b4, #7aa1d2);
    transform: rotateY(180deg);
    background-image: url('./assets/images/items.jpg');
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; 
    background-position: center; /* Prevent the image from repeating */
    width: 100%;
    height: 200px; /* Adjusted height for better visibility */
    border-radius: 25px;
    padding: 20px; /* Add padding for better content alignment */
  }

  .cardkit h3 {
    font-size: 20px; /* Adjusted font size */
  }

  .cardkit p {
    font-size: 14px; /* Adjusted font size */
    color: #575454;
  }

  .cardk, .cardj, .cardm, .cardd {
    width: 300px; /* Full width for better fit on small screens */
    height: 200px;
    padding: 20px;
    margin-bottom: 20px; /* Add margin for spacing */
  }

  .cardk-front, .cardj-front, .cardm-front, .cardd-front, 
  .cardk-back, .cardj-back, .cardm-back, .cardd-back {
    position: absolute;
    width: 300px;
    height: 200px;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* For Safari */
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cardk-inner, .cardj-inner, .cardm-inner, .cardd-inner {
    padding: 20px;
  }

  .cardk h3, .cardj h3, .cardm h3, .cardd h3 {
    font-size: 20px;
  }

  .cardk p, .cardj p, .cardm p, .cardd p {
    font-size: 14px;
  }

  .service-info {
    padding: 20px 10px;
    margin-left: 30px;
   margin-right: 20px;
    border-radius: 20px;
  }

  .service-info-content {
    flex-direction: column;
    align-items: center;
  }

  .service-info-column {
    gap: 10px; /* Reduced space between the cards */
  }

  .service-info-image {
    width: 250px; /* Adjusted size */
    height: 250px; /* Adjusted size */
  }

  /* Service page cards */
  .cardser1, .cardser2, .cardser3, 
  .cardser4, .cardser5, .cardser6 {
    width: 100%; /* Make cards full width */
    height: auto; /* Adjust height for content */
    padding: 8px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .cardser1 h3, .cardser2 h3, .cardser3 h3,
  .cardser4 h3, .cardser5 h3, .cardser6 h3 {
    font-size: 18px; /* Adjusted font size */
  }


  .tagline {
    font-size: 10px; /* Adjust font size for the tagline */
    color: #5a3e3e; /* Example color */
    margin-top: 5px; /* Example margin top */
  }

  .logo-img {
    width: 60px;
    height: auto;
    margin-right: 10px;
  }

  .logo-text {
    font-size: 18px;
  }

  .social-icon {
    font-size: 6px;
  }

  nav ul li a {
    font-size: 16px;
  }

  .map-container iframe {
    position: relative;
    top: -30px;
    height: 80px;
    margin-right: 15%;
  }

  .contact-card {
    border-radius: 5px;
    padding: 10px;
    height: 40%;
    margin-right: 25%;
  }

  .communication-container {
    flex-direction: column;
    align-items: center;
  }

  .communication {
    width: 300px;
    height: 300px;
  }

  .communication-text {
    max-width: 100%;
    font-size: 16px;
    text-align: center; /* Adjust text alignment for better readability on small screens */
  }

  .carda {
    width: 100%; /* Make the card take full width on small screens */
    height: 250px; /* Allow height to adjust based on content */
    padding: 20px;
    margin: 25px; /* Reduce padding for smaller screens */
  }

  .carda-front, .carda-back {
    padding: 20px; /* Adjust padding for inner content */
    font-size: 14px; /* Adjust font size for readability */
  }

  .carda h3 {
    font-size: 20px; /* Adjust heading size */
  }

  .carda p {
    font-size: 14px; /* Adjust paragraph size */
  }

  .carda-front-sta, .carda-front-sti, .carda-front-abc {
    background-size: cover; /* Ensure the entire image fits within the card */
    background-position: center;
    width: 100%;
    height: 100%;
  }

  .photo-info {
    padding: 10px 0; /* Adjust the padding as needed */
    margin: 0 5px;   /* Adjust the margin as needed */
     height: 200px;
  }
  
  .photo-info-image {
    position: relative;
    width: 100%;
     /* Adjust the height as needed */
    border-radius: 10px; /* Adjust the border-radius as needed */
    background-size: contain;
    background-repeat: no-repeat;
  }
  
}

@media (max-width: 420px) {
 
  .header {
    flex-direction: row;
    align-items: flex-start;
   
  }

  .logo-img {
    width: 80px; /* Adjust size as necessary */
    height: 50px;
    margin-left: 0;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
    background: white;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .social-icons {
    justify-content: flex-start;
    margin-top: 10px;
    margin-left: auto;
  }

  .social-icon {
    margin-left: 0;
    margin-right: 15px;
  }

  .hamburger {
    display: block;
    margin-top: 3%;
    
  }
  

  .card, .cardc {
    width: 85%;
  }

  
  /*kit*/

  .kit-info {
    padding: 20px 10px;
   width: 85%;
  }

  .kit-info-text {
    width: 100%;
    font-size: large;
  }

  .cardkit {
    width: 80%; /* Full width for better fit on small screens */
    height: 350px; /* Adjust height automatically */
    padding: 10px; /* Reduced padding for small screens */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cardkit-inner {
    width: 80%; /* Full width for better alignment */
    display: flex;
    height: 150px;
    flex-direction: column; /* Column direction for stacking */
    align-items: center; /* Center content */
    justify-content: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }

  .cardkit-front, .cardkit-back {
    width: 90%; /* Full width for both sides */
    height: 250px; /* Adjust height automatically */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; /* For Safari */
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  
  .cardkit-front {
    background: linear-gradient(to right, #fc5c7d, #6a82fb);
    color: rgba(0, 0, 0, 0.834);
    background-image: url('./assets/images/Kit.jpg');
    position: relative;
    width: 90%;
    height: 250px; /* Adjusted height for better visibility */
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-position: center;
    border-radius: 25px;
    padding: 20px; /* Add padding for better content alignment */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Add shadow for better appearance */
  }
  
  .cardkit-back {
    background: linear-gradient(to left, #cc95c0, #dbd4b4, #7aa1d2);
    transform: rotateY(180deg);
    background-image: url('./assets/images/items.jpg');
    background-size: contain; /* Ensure the entire image fits within the card */
    background-repeat: no-repeat; 
    background-position: center;/* Prevent the image from repeating */
    width: 80%;
    height: 150px; /* Adjusted height for better visibility */
    border-radius: 30px;
    padding: 20px; /* Add padding for better content alignment */
  }
  

  .cardkit h3 {
    font-size: 20px; /* Adjusted font size */
  }

  .cardkit p {
    font-size: 14px; /* Adjusted font size */
    color: #575454;
  }

  .cardk {
    width: 50%;
    height: 200px;
    padding: 30px;
  }

  
.cardk-front, .cardk-back {
  position: absolute;
  width: 100%;
  height: 200px;
  
}

  .cardk-inner {
    padding: 30px;
    
  }

  .cardk h3 {
    font-size: 20px;
  }

  .cardk p {
    font-size: 14px;
  }

  .cardj {
    width: 50%;
    height: 200px;
    padding: 30px;
  }

  
.cardj-front, .cardj-back {
  position: absolute;
  width: 100%;
  height: 200px;
  
}

  .cardj-inner {
    padding: 30px;
    
  }

  .cardj h3 {
    font-size: 20px;
  }

  .cardj p {
    font-size: 14px;
  }


  .cardm {
    width: 50%;
    height: 200px;
    padding: 30px;
  }

  
.cardm-front, .cardm-back {
  position: absolute;
  width: 100%;
  height: 200px;
  
}

  .cardm-inner {
    padding: 30px;
    
  }

  .cardm h3 {
    font-size: 20px;
  }

  .cardm p {
    font-size: 14px;
  }

  .cardd {
    width: 50%;
    height: 200px;
    padding: 30px;
  }

  
.cardd-front, .cardm-back {
  position: absolute;
  width: 100%;
  height: 200px;
  
}

  .cardd-inner {
    padding: 30px;
    
  }

  .cardd h3 {
    font-size: 20px;
  }

  .cardd p {
    font-size: 14px;
  }
/*service page */
  .service-info {
    padding: 20px 10px;
    margin-left: 30px;
   margin-right: 20px;
    border-radius: 20px;
  }

  .service-info-content {
    flex-direction: column;
    align-items: center;
  }

  .service-info-column {
    gap: 10px; /* Reduced space between the cards */
  }

  .service-info-image {
    width: 250px; /* Adjusted size */
    height: 250px; /* Adjusted size */
  }

  /* Service page cards */
  .cardser1, .cardser2, .cardser3, 
  .cardser4, .cardser5, .cardser6 {
    width: 100%; /* Make cards full width */
    height: auto; /* Adjust height for content */
    padding: 8px;
    border-radius: 20px;
    margin-bottom: 15px;
  }

  .cardser1 h3, .cardser2 h3, .cardser3 h3,
  .cardser4 h3, .cardser5 h3, .cardser6 h3 {
    font-size: 18px; /* Adjusted font size */
  }

  
.contact-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
 
  
  .contact-card {
    border-radius: 5px;
    padding: 10px;
    height: 40%;
    margin-left: 30%;
  }

  .call-form-section{
    border-radius: 10px;
    padding: 10px;
    height: 40%;
    width: 250px;
    margin-left: 2%;
    margin-right: 2%;
  }

  
.map-container iframe{
 margin-left: 15%;
 padding-top: 40px;
 height: 10%;
 
}

.school-info-text{
  width: 70%;
  text-align: center;
  font-size: smaller;
}
.school-info-image {
  width: 200px;
  height: 200px;
  animation: bounceMoveSmall 5s ease-in-out infinite, glowSmall 3s ease-in-out infinite;
}



/* Adjusted keyframes for smaller screens */
@keyframes bounceMoveSmall {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(15px, -30px); /* Adjusted move right */
  }
  40% {
    transform: translate(-100px, -60px); /* Adjusted move left */
  }
  60% {
    transform: translate(15px, 30px); /* Adjusted move right */
  }
  80% {
    transform: translate(-75px, 60px); /* Adjusted move left */
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes glowSmall {
  0%, 100% {
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.9);
  }
}



.photo-info {
  padding: 10px 0; /* Adjust the padding as needed */
  margin: 0 5px;   /* Adjust the margin as needed */
   height: 150px;
}

.photo-info-image {
  position: relative;
  width: 100%;
   /* Adjust the height as needed */
  border-radius: 10px; /* Adjust the border-radius as needed */
  background-size: contain;
  background-repeat: no-repeat;
}

 
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    color: #020202;
    border: 1px solid transparent; /* Transparent border */
    border-radius: 20%;
    
    cursor: pointer;
    font-size: 20px;
    transition: opacity 0.3s ease;
    text-align: center; /* Center the arrow */
    line-height: 30px; /* Center the arrow vertically */
  }

  .scroll-to-top.visible {
    display: block;
  }



}